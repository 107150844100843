import React from 'react';

import Link from '@components/Link';
import LinkEdit from '@components/LinkEdit';
import HarrisButton from '@presenters/web/components/Leads/HarrisButton';

import ContactInformationPhone from './ContactInformationPhone';

import { DynamicData } from '@backend/modules/domui/types';

import { useTranslation } from '@external/react-i18next';

import { PhoneNumber } from '@typings/graphql';

enum AddressType {
  Business = 'Business',
  Home = 'Home',
  HostCountry = 'HostCountry',
  Localized = 'Localized',
  Project = 'Project',
}

type Address = {
  type: AddressType;
  isPrimary: boolean;
  lineOne?: string | null | undefined;
  lineTwo?: string | null | undefined;
  lineThree?: string | null | undefined;
  country?: string | null | undefined;
  countryId: string;
  city?: string | null | undefined;
  stateId?: string | null | undefined;
  state?: string | null | undefined;
  postalCode?: string | null | undefined;
};

type ContactInfoProps = {
  emails?: DynamicData;
  phones?: DynamicData;
  addresses?: DynamicData;

  websites?: DynamicData;

  officialLanguage?: DynamicData;

  isEditable: boolean;
  editContactInformationLink: string;
};

const ContactInformation: React.FC<ContactInfoProps> = ({
  emails,
  phones,
  addresses,
  websites,
  officialLanguage,
  editContactInformationLink,
  isEditable,
}) => {
  const { t } = useTranslation();

  const title = t(
    'club-details.contact-information.title',
    'Contact Information'
  );

  const ensureHttpPrefix = (url: string): string => {
    if (!/^https?:\/\//i.test(url)) {
      return `http://${url}`;
    }
    return url;
  };

  const primaryPhones =
    phones?.filter(
      (phone: { isPrimary: boolean; isFax: boolean }) =>
        phone?.isPrimary && !phone?.isFax
    ) || [];
  const faxPhones =
    phones?.filter((phone: { isFax: boolean }) => phone?.isFax) || [];

  const primaryAddresses =
    addresses?.filter(
      (address: { isPrimary: boolean }) => address?.isPrimary
    ) || [];

  return (
    <div>
      <div className="flex justify-between">
        <h2>{title}</h2>
        {isEditable ? (
          <LinkEdit path={editContactInformationLink} component={title} />
        ) : (
          <span className="h5 alternate mb-0 text-gray-300">
            {t('club-details.rotary-club.not-editable', 'Not Editable')}
          </span>
        )}
      </div>

      <div className="flex flex-col">
        {!!emails?.length && (
          <div className="my-4">
            <h5>
              {t(
                'club-details.contact-information.email-address-label',
                'Email'
              )}
            </h5>
            {emails
              .filter((email: { isPrimary: boolean }) => email.isPrimary)
              .map((email: { email: string; isPrimary: boolean }) => (
                <span key={email.email}>
                  <Link to={`mailto:${email.email}`}>{email.email}</Link>
                </span>
              ))}
          </div>
        )}

        {primaryPhones && primaryPhones.length > 0 && (
          <div className="my-4">
            <h5>
              {t(
                'club-details.contact-information.phone-number-label',
                'Phone'
              )}
            </h5>
            <span>
              {primaryPhones.map((phone: PhoneNumber) => (
                <ContactInformationPhone phone={phone} key={phone.number} />
              ))}
            </span>
          </div>
        )}
        {faxPhones && faxPhones.length > 0 && (
          <div className="my-4">
            <h5>
              {t('club-details.contact-information.fax-number-label', 'Fax')}
            </h5>
            <span>
              {faxPhones.map((phone: PhoneNumber) => (
                <ContactInformationPhone phone={phone} key={phone.number} />
              ))}
            </span>
          </div>
        )}
        {websites?.primaryWebsite && (
          <div className="my-4">
            <h5>
              {t('club-details.contact-information.website-label', 'Website')}
            </h5>
            <span>
              <Link
                to={ensureHttpPrefix(websites.primaryWebsite || '')}
                target="_blank"
              >
                {websites.primaryWebsite}
              </Link>
            </span>
          </div>
        )}
        {!!officialLanguage && (
          <div className="my-4">
            <h5>
              {t(
                'club-details.contact-information.language-label',
                'Rotary language'
              )}
            </h5>
            <span>{officialLanguage.language}</span>
          </div>
        )}
        {Array.isArray(primaryAddresses) && primaryAddresses.length > 0 && (
          <div className="my-4">
            {primaryAddresses.map((address: Address) => (
              <div key={`${address.lineOne} ${address.type}`}>
                <h5>
                  {t(
                    'club-details.contact-information.mailing-address-label',
                    'Mailing address'
                  )}
                </h5>
                <div>
                  {address.lineOne && (
                    <span className="block">{address.lineOne}</span>
                  )}
                  {address.lineTwo && (
                    <span className="block">{address.lineTwo}</span>
                  )}
                  {address.lineThree && (
                    <span className="block">{address.lineThree}</span>
                  )}
                  {address.city && <span>{address.city}</span>}
                  {address.postalCode && <span>, {address.postalCode}</span>}
                  {address.country && <span>, {address.country}</span>}
                  {address.state && (
                    <span className="block"> {address.state}</span>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        {/* {joinClubButton && ( */}
        <div className="my-4">
          <HarrisButton variant="secondary">Join this club</HarrisButton>
        </div>
        {/* )} */}
        {/* {isUserLoggedIn && showMailingAddress && ( */}
      </div>
    </div>
  );
};

export default ContactInformation;
