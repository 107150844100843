/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { DynamicData } from '@backend/modules/domui/types';

export default (input: DynamicData) => {
  const primaryAddress =
    input?.addresses && input?.addresses?.length > 0
      ? input?.addresses?.find(
          (address: { isPrimary: boolean }) => address?.isPrimary
        )
      : '';
  const primaryEmail =
    input?.emails && input?.emails.length > 0
      ? input?.emails?.find((email: { isPrimary: boolean }) => email?.isPrimary)
      : '';

  const primaryPhone = (() => {
    if (input?.phones && input?.phones?.length > 0) {
      // Filter phones to get only those where isPrimary is true and isFax is false
      const primaryPhones = input?.phones?.filter(
        (phone: { isPrimary: boolean; isFax: boolean }) =>
          phone?.isPrimary && !phone?.isFax
      );

      // If there's exactly one primary phone, return it
      if (primaryPhones?.length === 1) {
        return primaryPhones[0];
      }

      // If there are multiple primary phones, return the first one
      if (primaryPhones?.length > 1) {
        return primaryPhones[0];
      }
    }

    // If input.phones is not defined or empty, return an empty string
    return '';
  })();

  const primaryFax =
    input?.phones && input?.phones?.length > 0
      ? input?.phones?.find(
          (phone: { isFax: boolean; isPrimary: boolean }) =>
            phone?.isFax && phone?.isPrimary
        ) ||
        input?.phones?.find((phone: { isFax: boolean }) => phone?.isFax) ||
        input?.phones
      : '';

  const officialLanguage = input?.officialLanguage || {
    languageId: '',
    id: null,
  };

  const languageId = officialLanguage?.languageId || '';
  const id = officialLanguage?.id || null;

  return {
    address: {
      countryId: primaryAddress?.country || '',
      city: primaryAddress?.city || '',
      postalCode: primaryAddress?.postalCode || '',
      lineOne: primaryAddress?.lineOne || '',
      lineTwo: primaryAddress?.lineTwo || '',
      lineThree: primaryAddress?.lineThree || '',
      state: primaryAddress?.state || '',
      stateId: primaryAddress?.stateId || '',
      internationalProvince: primaryAddress?.internationalProvince || '',
      hasStates: primaryAddress?.hasStates || false,
      id: primaryAddress?.id || null,
      country: primaryAddress?.country || '',
      isPrimary: primaryAddress?.isPrimary || '',
      type: primaryAddress?.type || '',
    },
    primaryEmail: primaryEmail?.email || '',
    primaryWebsite: input?.websites?.primaryWebsite || '',
    primaryPhone: {
      number: primaryPhone?.number || '',
      countryId: primaryPhone?.countryId || '',
      extension: primaryPhone?.extension || '',
      country: primaryPhone?.country || '',
      id: primaryPhone?.id || null,
      isPrimary: primaryPhone?.isPrimary || null,
      isFax: primaryPhone?.isFax,
    },
    primaryFax: {
      number: primaryFax?.number || '',
      countryId: primaryFax?.countryId || '',
      extension: primaryFax?.extension || '',
      country: primaryFax?.country || '',
      id: primaryFax?.id || null,
      isPrimary: primaryFax?.isPrimary,
      isFax: primaryFax?.isFax,
    },
    officialLanguage: {
      languageId,
      id,
    },
    primaryWebsiteID: input?.websites?.id || null,
    primaryEmailID: primaryEmail?.id || null,
    primaryEmailType: primaryEmail?.type || null,
    primaryEmailIsPrimary: primaryEmail?.isPrimary || null,
    otherWebsite: input?.websites?.otherWebsite || null,
    emails: input?.emails,
    addresses: input?.addresses,
    phones: input?.phones,
    websites: input?.websites,
    officialLanguages: input?.officialLanguage,
  };
};
