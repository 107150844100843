import { useEffect } from 'react';

import mapValues from 'lodash/mapValues';

import { Option } from '@components/Formik/MultiSelectFilter/types';

import { getValuesFromOptions } from '@use-cases/districts';

import { useClubMembersSearch } from '@domui-hooks/useClubMembersSearch';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};

type filter = {
  roles?: Option[];
  memberTypes?: Option[];
};

type ClubRosterFilter = {
  orgId: string;
  name: string;
  email: string;
  filters: filter;
};

export const useFetchClubMembersWithFilters = (
  currentPage: number,
  pageSize: number,
  filterValues: ClubRosterFilter,
  selectedValues: filter
) => {
  const { data, loading, error, clubMembersSearch } = useClubMembersSearch();
  const fetchData = {
    totalCount: 0,
    memberSearchInfo: [],
  };
  const clubMembersSearchData = data?.status === 404 ? fetchData : data;

  const filters = mapValues(selectedValues, item =>
    getValuesFromOptions(item as Option[])
  );

  useEffect(() => {
    if (filterValues) {
      clubMembersSearch(currentPage, pageSize, filterValues);
    }
  }, [currentPage, pageSize, filterValues]);

  return {
    clubMembersSearchData,
    loading,
    error,
    filters,
  };
};
