import React, { useEffect, useRef } from 'react';

import { useField } from 'formik';
import isEqual from 'lodash/isEqual';
import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';

import Element, { ElementProps } from '@components/Formik/Element';
import { Icon } from '@components/Icon';

import { customStyles } from './customStyles';
import Dropdown from './Dropdown';
import MenuList from './MenuList';
import OptionComponent from './OptionComponent';

import tailwind from '@styles/tailwind.js';

import ReactSelect, {
  InputActionMeta,
  OptionsType,
  OptionTypeBase,
  ValueType,
} from '@external/react-select';
import { useOutsideCallback } from '@hooks/useOutsideCallback';
import { useWindowSize } from '@hooks/useWindowSize';

import { MultiSelectFilterType, Option } from './types';

export interface MultiSelectFilterProps
  extends ElementProps,
    MultiSelectFilterType {}

const MultiSelectFilter: React.FC<MultiSelectFilterProps> = props => {
  const {
    name,
    options,
    inputPlaceholder,
    onChange,
    applyFilter,
    resetFilter,
    title,
    applyBtnLabel = {
      key: 'global.apply-label',
      name: 'Apply',
    },
    isSearchable = true,
  } = props;
  const inputId = `${name}-input`;

  const [field, , helpers] = useField<ValueType<OptionTypeBase>>(name);

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const [selectedOptions, setSelectedOptions] = React.useState<
    ValueType<OptionTypeBase>
  >(field.value);

  const [sortedOptions, setSortedOptions] = React.useState<
    OptionsType<OptionTypeBase> | undefined
  >(options);

  const [inputValue, setInputValue] = React.useState<string>('');

  const menuRef = useRef(null);
  const windowWidth = useWindowSize().width;
  const isAutoFocusActive =
    windowWidth > parseInt(tailwind.theme.screens.desktop, 10);

  useEffect(() => {
    if (field.value?.length === 0) {
      setSelectedOptions([]);
    }
  }, [field.value, setSelectedOptions]);

  useEffect(() => {
    if (!isEqual(sortBy(sortedOptions, ['id']), sortBy(options, ['id']))) {
      setSortedOptions(options);
    }
  }, [options]);

  const initialOptions = options?.map(option => option) || [];

  const resetOptions = () => {
    const selectedIds = selectedOptions
      ? selectedOptions.map((option: OptionTypeBase) => option.id)
      : [];

    const initialOptionIds = initialOptions.map(({ id }) => id);

    const [checkedOptions, uncheckedOptions] = partition(
      sortedOptions,
      option => selectedIds.includes(option.id)
    );

    checkedOptions.sort(
      (prv, nxt) =>
        initialOptionIds.indexOf(prv.id) - initialOptionIds.indexOf(nxt.id)
    );
    uncheckedOptions.sort(
      (prv, nxt) =>
        initialOptionIds.indexOf(prv.id) - initialOptionIds.indexOf(nxt.id)
    );

    setSortedOptions([...checkedOptions, ...uncheckedOptions]);
  };

  const toggleOpen = () => {
    resetOptions();
    setIsOpen(!isOpen);
    setInputValue('');
  };

  const handleChange = (options: ValueType<OptionTypeBase>) => {
    setSelectedOptions(options);
    helpers.setValue(options);
    onChange?.(options as Option[]);
  };

  const handleClearAll = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    resetFilter();
    setSelectedOptions([]);
    helpers.setValue([]);
    setSortedOptions(initialOptions);
    if (isOpen) {
      toggleOpen();
    }
  };

  const handleApplyFilter = () => {
    applyFilter(selectedOptions as Option[]);
    setInputValue('');
  };

  // react-select has no logic to save input value after selecting an item
  // https://github.com/JedWatson/react-select/issues/3210
  const handleInputChange = (query: string, { action }: InputActionMeta) => {
    if (action === 'input-change') {
      setInputValue(query);
      return query;
    }

    return inputValue;
  };

  useOutsideCallback(menuRef, handleApplyFilter, target => {
    const finalTarget =
      target.tagName === 'path' && target.parentElement
        ? target.parentElement
        : target;

    return (
      finalTarget.id !== inputId &&
      !finalTarget.classList?.contains('close-svg')
    );
  });

  const target = (
    <div
      className="flex items-baseline cursor-pointer tap-highlight-color-transparent"
      data-testid="dropdown"
      onClick={toggleOpen}
    >
      <span className="text-xs">{title}</span>
      {!!selectedOptions?.length && (
        <span className="text-dark-blue-400 font-bold self-center ml-1 text-3xs">
          {selectedOptions?.length}
        </span>
      )}
      {!!selectedOptions?.length && (
        <div
          className="self-center z-20"
          data-testid="clear-all-selected-button"
          onClick={handleClearAll}
        >
          <Icon
            color="gray-300"
            name="circles/close"
            size="14"
            className="ml-1 text-gray-300 close-svg"
          />
        </div>
      )}
      <Icon
        name={`arrows/${isOpen ? 'up' : 'down'}`}
        size="14"
        color="dark-blue-400"
        className="self-center ml-auto desktop:ml-2 mt-px"
      />
    </div>
  );

  return (
    <Element {...props}>
      <Dropdown isOpen={isOpen} onClose={toggleOpen} target={target}>
        <ReactSelect
          backspaceRemovesValue={false}
          controlShouldRenderValue={false}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          autoFocus={isAutoFocusActive}
          isMulti
          styles={customStyles}
          onChange={handleChange}
          options={sortedOptions}
          tabSelectsValue={false}
          inputId={inputId}
          windowWidth={windowWidth}
          value={selectedOptions}
          isSearchable={isSearchable}
          placeholder={inputPlaceholder}
          handleApplyFilter={handleApplyFilter}
          applyBtnLabel={applyBtnLabel}
          menuRef={menuRef}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          components={{
            Option: OptionComponent,
            MenuList,
            IndicatorSeparator: null,
            DropdownIndicator: null,
          }}
        />
      </Dropdown>
    </Element>
  );
};

export default MultiSelectFilter;
