/* eslint-disable @typescript-eslint/no-explicit-any */
export type DynamicData = Record<string, any> | null | undefined;
export type DomuiError =
  | ({
      statusCode?: string;
      message?: string;
      description?: string;
    } & object)
  | undefined
  | null;

export enum ClubType {
  RotaractClub = 'Rotaract Club',
  RotaractSatelliteClub = 'Rotaract Satellite Club',
  RotaryClub = 'Rotary Club',
  SatelliteClub = 'Satellite Club',
}

export enum ClubTypeFilterValue {
  RotaractClub = 'Rotaract Club',
  RotaractSatelliteClub = 'Rotaract Satellite Club',
  RotaryClub = 'Rotary Club',
  SatelliteClub = 'Satellite Club',
}
