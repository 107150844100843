/* eslint-disable @typescript-eslint/no-explicit-any */
// Types
import { isEqual } from 'lodash';

import { DynamicData } from '@backend/modules/domui/types';
import {
  BasicLanguageInput,
  FormAddressItem,
  FormEmailItem,
  FormPhoneItem,
  FormWebsiteItem,
} from 'src/domui/domain/clubs/types';

import { EmailType } from '@typings/graphql';

export const createEmailWithID = (
  email: string,
  id: string,
  isPrimary: boolean,
  type: string
) => ({
  id: email === '' && id ? '' : id,
  email: String(email),
  isPrimary: isPrimary ?? true,
  type: Object.values(EmailType).includes(type?.toLowerCase() as EmailType)
    ? (type?.toLowerCase() as EmailType)
    : (EmailType.Business.toLowerCase() as EmailType),
});
export const fillMissingValues = (
  target: FormPhoneItem[],
  source: FormPhoneItem[]
): FormPhoneItem[] =>
  target.map((item, index) => ({
    ...source[index],
    ...item,
    id: item?.id ?? source[index]?.id,
    extension:
      item.extension || item.number
        ? item?.extension ?? source[index]?.extension
        : '',
    countryId:
      item.extension || item.number
        ? item?.countryId ?? source[index]?.countryId
        : '',
    country:
      item.extension || item.number
        ? item?.country ?? source[index]?.country
        : '',
    isFax: item?.isFax ?? source[index]?.isFax,
    isPrimary: item?.isPrimary ?? source[index]?.isPrimary,
  }));

export const createFilledItem = (
  item: Partial<FormPhoneItem>,
  isPrimary: boolean,
  isFax: boolean
): FormPhoneItem => ({
  id: item?.id ?? null,
  isPrimary: item?.isPrimary ?? isPrimary,
  isFax: item?.isFax ?? isFax,
  country: item?.country ?? '',
  countryId: item?.countryId ?? '',
  extension: item?.extension ?? null,
  number: item?.number ?? '',
  type: item?.type ?? null,
});
export const createWebsiteWithID = (
  website: string,
  id: string,
  otherWebsites: string
) => ({
  id: website === '' && id ? '' : id,
  primaryWebsite: website || '',
  otherWebsites: otherWebsites || '',
  action: '',
});

export function mapToBasicLanguageInput(item: DynamicData): BasicLanguageInput {
  return {
    action: item?.action || null,
    id: item?.id || null,
    languageId: item?.languageId || '',
    __typename: 'Language',
  };
}
// emailUtils.js
export function removeInvalidEmails(emailArray: DynamicData[]) {
  return emailArray.filter(
    record => !(record?.email === '' && record?.action === 'Add')
  );
}

export function removeInvalidWebsites(websitesArray: DynamicData[]) {
  return websitesArray.filter(
    record => !(record?.primaryWebsite === '' && record?.action === 'Add')
  );
}

export const ensureArray = (value: unknown) =>
  Array.isArray(value) ? value : [value];

type InfoType =
  | FormEmailItem
  | FormAddressItem
  | FormPhoneItem
  | FormWebsiteItem
  | BasicLanguageInput;

export const processContactInfoForDomino = (
  formInfo: InfoType[],
  submittedInfo: InfoType[]
) => {
  const formInfoArray = Array.isArray(formInfo) ? formInfo : [];
  const formInfoIdValuePair = new Map<string, InfoType>(
    formInfoArray
      .filter(
        (info): info is InfoType => info != null && typeof info.id === 'string'
      )
      .map(info => [info.id as string, info])
  );

  const formInfoIds = new Set(formInfoIdValuePair.keys());

  const submittedInfoArray = Array.isArray(submittedInfo) ? submittedInfo : [];

  const submittedInfoIdValuePair = new Map<string, InfoType>(
    submittedInfoArray
      .filter((info): info is InfoType => typeof info.id === 'string')
      .map(info => [info.id as string, info])
  );
  const submittedInfoIds = new Set(submittedInfoIdValuePair.keys());

  // If Submitted Info has missing id's, they are considered deleted and action is "Deleted".
  const deletedInfoIds = Array.from(formInfoIds).filter(info => {
    return !submittedInfoIds.has(info);
  });

  const deletedInfos = deletedInfoIds.map(infoId => {
    const deletedInfo = formInfoIdValuePair.get(infoId);
    if (deletedInfo) {
      delete (deletedInfo as any).__typename;
    }
    return { ...(deletedInfo || { infoId }), action: 'Delete' };
  });

  const addedUpdatedInfos = submittedInfo.map(info => {
    const actionUpdatedInfo = { ...info };
    // If Submitted Info does not have id, its a newly added Info and action is "Add".
    if (!info.id) {
      actionUpdatedInfo.action = 'Add';
    } else {
      const selectedInfo = formInfoIdValuePair.get(info.id);

      if (selectedInfo) {
        // If Form Info values are different from submitted Info values, action is "Update"
        if (!isEqual(info, selectedInfo)) {
          actionUpdatedInfo.action = 'Update';
        }
      }
    }

    delete (actionUpdatedInfo as any).__typename;
    return actionUpdatedInfo;
  });

  return [...addedUpdatedInfos, ...deletedInfos];
};
