/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import { Field, Form, Formik } from 'formik';
import { isEmpty, values as objectValues } from 'lodash';

import Divider from '@components/Divider';
import TextField from '@components/Formik/PasswordField';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import ClubCTAButtons from '@presenters/web/components/ClubsSaveChangesButtons';

import {
  createEmailWithID,
  createFilledItem,
  createWebsiteWithID,
  ensureArray,
  fillMissingValues,
  mapToBasicLanguageInput,
  processContactInfoForDomino,
  removeInvalidEmails,
  removeInvalidWebsites,
} from './utils';
import editClubContactValidationSchema from './validation';

import { useModal, useStopBrowserNavigate } from '@use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';
import { getStorageID } from '@use-cases/storage';

import { localizedNavigate } from '@utils/localized-navigate';

import AddressFields from '@domui-components/Formik/Address';
import PhoneInput from '@domui-components/Formik/PhoneInput';
import LanguageSelect from '@domui-components/Formik/Select/LanguageSelect';
import mapContactsValuesToFormInput from '@domui-domain/clubs/mappers/mapContactsValuesToFormInput';
import { BasicLanguageInput, FormPhoneItem } from '@domui-domain/clubs/types';
import { DynamicData } from '@domui-domain/type';
import { useFetchLanguages } from '@domui-hooks/useFetchLanguages';
import { useFetchOrganization } from '@domui-hooks/useFetchOrganization';
import { useUpdateClubContactInfo } from '@domui-hooks/useUpdateClubContactInfo';
import { getBackButtonLabel, getClubDetailsPath } from '@domui-use-cases/clubs';
import Metadata from '@domui-utils/workflow/metadata';

import { useTranslation } from '@external/react-i18next';

import { EmailType } from '@typings/graphql';

const EditClubContact: React.FC<RouteComponentProps & {
  clubId: string;
}> = ({ clubId }) => {
  const { t } = useTranslation();
  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const clubDetailsPath = getClubDetailsPath(clubId);

  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    async function fetchCountryData() {
      const wfService = new Metadata();
      const response = await wfService.getCountryData();
      setCountryData(response?.countries);
    }
    fetchCountryData();
  }, []);

  const {
    data: languages,
    loading: languagesLoading,
    error: languagesError,
    fetchLanguages,
  } = useFetchLanguages();

  useEffect(() => {
    fetchLanguages();
  }, []);
  useErrorHandling(
    languagesError?.message,
    !!languagesError,
    'fetch-languages.error'
  );
  const {
    data: getOrganizationData,
    loading: getOrganizationLoading,
    fetchOrganization,
  } = useFetchOrganization();

  useEffect(() => {
    if (clubId) {
      fetchOrganization(clubId);
    }
  }, [clubId]);

  const {
    data: newData,
    loading: updatedLoading,
    error: updatedError,
    updateClubContactInfo,
  } = useUpdateClubContactInfo();

  useErrorHandling(
    updatedError?.description
      ? updatedError.description
      : t('update-contactInfo.error', 'An error occurred.'),
    !!updatedError,
    'update-contactInfo.error'
  );

  const setUpdateData = async (updateData: DynamicData) => {
    await updateClubContactInfo(clubId, updateData, clubDetailsPath);
  };

  const clearButtonStyle: string = 'absolute -mt-12 -ml-16';

  const modalBackHandler = () => {
    show(true);
  };

  const { globalHide } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });

  if (getOrganizationLoading || !getOrganizationData) {
    return <Loading />;
  }

  if (updatedLoading && !getOrganizationData) {
    return <Loading />;
  }

  const modalOnConfirmHandler = () => {
    localizedNavigate(clubDetailsPath);
  };

  const formValues = mapContactsValuesToFormInput(getOrganizationData);

  const handleFormSubmitForDomino = async (inputValues: DynamicData) => {
    const values = { ...inputValues };

    values.primaryPhone = fillMissingValues(
      ensureArray(values?.primaryPhone),
      ensureArray(inputValues?.primaryPhone)
    );
    values.primaryFax = fillMissingValues(
      ensureArray(values?.primaryFax),
      ensureArray(inputValues?.primaryFax)
    );
    const updatedPhonesWithisPrimary: FormPhoneItem[] = [
      ...values.primaryPhone.map((item: Partial<FormPhoneItem>) =>
        createFilledItem(item, true, false)
      ),
      ...values.primaryFax.map((item: Partial<FormPhoneItem>) =>
        createFilledItem(item, false, true)
      ),
    ].filter(Boolean);

    const primaryEmail =
      inputValues?.emails?.filter(
        (email: { isPrimary: boolean }) => email.isPrimary
      ) || [];
    const updatedEmails = removeInvalidEmails(
      processContactInfoForDomino(primaryEmail, [
        createEmailWithID(
          values?.primaryEmail,
          values?.primaryEmailID,
          values?.primaryEmailIsPrimary,
          values?.primaryEmailType
        ),
      ]) || []
    );

    const primaryAddress =
      inputValues?.addresses?.filter(
        (address: { isPrimary: boolean }) => address.isPrimary
      ) || [];
    if (primaryAddress.length === 0 && values?.address) {
      values.address.isPrimary = true;
      values.address.type = 'Business';
    }
    const updatedAddress =
      processContactInfoForDomino(primaryAddress, [values?.address]) || [];

    const updatedPhones =
      processContactInfoForDomino(
        inputValues?.phones?.filter(
          (phone: { isPrimary: boolean; isFax: boolean }) =>
            !(phone?.isPrimary === false && phone?.isFax === false)
        ),
        updatedPhonesWithisPrimary
      ) || [];

    const websitesArray = ensureArray(inputValues?.websites);
    const updatedWebsite =
      removeInvalidWebsites(
        processContactInfoForDomino(websitesArray, [
          createWebsiteWithID(
            values?.primaryWebsite,
            values?.primaryWebsiteID,
            values?.websites?.otherWebsites
          ),
        ])
      ) || [];

    const officialLanguagesArray = ensureArray(
      formValues?.officialLanguages
    ).filter(item => item !== null && item !== undefined);

    const updatedLanguage: BasicLanguageInput[] = processContactInfoForDomino(
      officialLanguagesArray,
      [values?.officialLanguage]
    ).map(mapToBasicLanguageInput);

    const contactInfo = {
      contactInfo: {
        addresses: [...updatedAddress],
        emails: [...updatedEmails],
        phones: [...updatedPhones],
        websites: [...updatedWebsite],
        officialLanguage:
          updatedLanguage.length > 0 ? [...updatedLanguage] : [],
      },
    };

    await setUpdateData(contactInfo);

    const userCacheId = `user-account-${clubId}`;
    const previousData = sessionStorage.getItem(
      getStorageID(userCacheId)
    ) as string;
    const prevData = JSON.parse(previousData);

    const newData = {
      getOrganization: {
        ...prevData?.getOrganization,
        email: updatedEmails,
        primaryPhone: updatedPhones,

        primaryAddress: updatedAddress,
      },
    };
    sessionStorage.setItem(getStorageID(userCacheId), JSON.stringify(newData));
  };

  return (
    <div className="mt-4">
      <LinkPrevious
        path=""
        showModal={modalBackHandler}
        label={getBackButtonLabel(t)}
        classes="mt-4"
      />

      <h2 className="my-3">
        {t('club-editcontact.title', 'Edit Contact Information')}
      </h2>
      <Formik
        initialValues={formValues}
        onSubmit={handleFormSubmitForDomino}
        validationSchema={editClubContactValidationSchema(t)}
      >
        {({ values, isSubmitting, isValid, dirty }) => {
          const isPrimaryPhoneRequired = !!values.primaryPhone?.number;
          const isPrimaryFaxRequired = !!values.primaryFax?.number;

          const isPrimaryPhoneClearButtonDisabled = objectValues(
            values.primaryPhone
          ).every(isEmpty);
          const isPrimaryFaxClearButtonDisabled = objectValues(
            values.primaryFax
          ).every(isEmpty);
          const isAddressClearButtonDisabled = objectValues(
            values.address
          ).every(isEmpty);

          const primaryPhoneLabels = {
            phoneCode: t(
              'club-editcontact.phone-label-extension',
              'Phone Extension'
            ),
            phoneExtension: t('form.address.phone-extension', 'Extension'),
            phoneNumber: t('club-editcontact.phone-label', 'Phone'),
          };
          const primaryFaxLabels = {
            phoneCode: t(
              'club-editcontact.fax-label-extension',
              'Fax Extension'
            ),
            phoneExtension: t('form.address.phone-extension', 'Extension'),
            phoneNumber: t('club-editcontact.fax-label', 'Fax'),
          };

          return (
            <Form className="max-w-lg mt-6">
              <h2 className="mt-2 desktop:mt-6">
                {t('contact.us.form.email-label', 'Email')}
              </h2>

              <TextField
                name="primaryEmail"
                label={t('club-editcontact.email-label', 'Email')}
              />
              <Divider size={8} />
              <h2 className="mt-2 desktop:mt-6">
                {t('edit-personal-details.subheader-phone', 'Phone')}
              </h2>
              <PhoneInput
                suffix="primaryPhone"
                labels={primaryPhoneLabels}
                isCountryFlagRequired={isPrimaryPhoneRequired}
                displayClearButton
                isClearButtonDisabled={isPrimaryPhoneClearButtonDisabled}
                clearButtonStyle={clearButtonStyle}
              />
              <Divider size={8} />
              <h2 className="mt-2 desktop:mt-6">
                {t('club-editcontact.fax', 'Fax')}
              </h2>
              <PhoneInput
                suffix="primaryFax"
                labels={primaryFaxLabels}
                isCountryFlagRequired={isPrimaryFaxRequired}
                displayClearButton
                isClearButtonDisabled={isPrimaryFaxClearButtonDisabled}
                clearButtonStyle={clearButtonStyle}
              />
              <Divider size={8} />
              <h2 className="mt-2 desktop:mt-6">
                {t('club-editcontact.website-label', 'Website')}
              </h2>
              <TextField
                name="primaryWebsite"
                label={t('club-editcontact.website-label', 'Website')}
              />
              <Divider size={8} />
              <h2 className="mt-2 desktop:mt-6">
                {t('form.address.address-label', 'Address')}
              </h2>
              <AddressFields
                countryData={countryData}
                countryId={values.address?.country}
                mainLabel={t(
                  'club-editcontact.address-label',
                  'Mailing address'
                )}
                name={{ hasStates: 'address.hasStates' }}
                displayClearButton
                isClearButtonDisabled={isAddressClearButtonDisabled}
                clearButtonStyle={clearButtonStyle}
              />
              <Divider size={8} />
              <h2 className="mt-2 desktop:mt-6">{t('language', 'Language')}</h2>
              <LanguageSelect
                name="officialLanguage.languageId"
                label={t('club-editcontact.languages-label', 'Rotary language')}
                languages={languages}
                languagesLoading={languagesLoading}
                required
                disabled
              />
              <ClubCTAButtons
                isSubmitting={isSubmitting}
                isValid={isValid && dirty}
                onCancelClick={modalBackHandler}
              />
            </Form>
          );
        }}
      </Formik>
      <LeaveFormConfirmationModal
        isOpen={isShowing}
        closeModal={globalHide}
        onConfirm={modalOnConfirmHandler}
      />
    </div>
  );
};

export default EditClubContact;
