/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import classNames from 'classnames';
// import moment from 'moment';

import { Avatar } from '@components/Avatar';
// import ClubsList from '@components/ClubsList';
// import { ContextMenu } from '@components/ContextMenu';
import Link from '@components/Link';

import { mapRoleLabelForRotaractors } from '../../utils';
// import RoleAndTypeLabel from '../RoleAndTypeLabel';

// import { getClubMembersPath } from '@use-cases/clubs/helpers';

import { getClubDestinationURL } from '@utils/getClubDestination';
import { storePreviousPagePath } from '@utils/storePreviousPagePath';

import { useTranslation } from '@external/react-i18next';

// import { RosterResultRowFragment } from '@typings/operations';

type RosterResultRowFragment = {
  clubId: string;
  nfId: string;
  profile: {
    firstName: string;
    id: string;
    lastName: string;
    middleName: string;
    photoUri: string;
    localizedName: string;
  };
  email: {
    email: string;
    isPrimary: boolean;
    type: string;
  };
  phone: {
    isFax: boolean;
    isPrimary: boolean;
    phone: string;
    type: string;
  };
  roles: {
    endDate: string;
    role: string;
    startDate: string;
  };
};

// export interface IRosterResultDesktop
//   extends RosterResultRowFragment,
//     RosterResultProps {}

// const TERMINATION_DATE_FORMAT = 'DD MMM YYYY';
// const CURRENT_DATE_FORMAT = 'YYYY-MM-DD';

const RosterResultDesktop: React.FC<RosterResultRowFragment> = ({
  clubId,
  profile,
  email,
  phone,
  roles,
  nfId,
  // id,
  // clubType,
  // name,
  // email,
  // localizedName,
  // photoUri,
  // phoneNumber,
  // riIndividualId,
  // contextMenuItems,
  // activeAssociatedClubs,
  // type,
  // isDeceasedFiltered,
  // isTerminatedFiltered,
  // isLeadershipRole,
  // dates,
  // terminationHistory,
  // hasActiveMembershipInOtherClubs,
  // isDeceasedOutsideOfClub,
  // isTerminatedAndHasActiveMembershipInOtherClubs,
  // activeMembership,
  // clubLeadershipInfo,
  // admissionDate,
  // isDeceased,
  // isNonMember,
  // activeLeaders,
  // membershipsFromThisClub,
}) => {
  const { t } = useTranslation();

  // const isMemberIdShown = isLeadershipRole && activeMembership && !isDeceased;

  const isNameHyperlinked = true;
  //   (hasActiveMembershipInOtherClubs ||
  //     isTerminatedAndHasActiveMembershipInOtherClubs ||
  //     isNonMember) &&
  //   !isDeceased;

  const clubRosterPath = getClubDestinationURL(clubId, 'roster');

  const primaryEmail: string = email?.email ? email?.email : '';
  const primaryPhone: string = phone?.phone ? phone?.phone : '';

  return (
    <div
      data-label="RosterResult"
      className="hidden desktop:flex items-start flex-1 pl-6"
    >
      <Avatar
        photoUri={profile?.photoUri || undefined}
        className="flex justify-center self-center member-row-avatar desktop:w-20"
      />
      <div className="w-full member-row-info">
        <div className="flex flex-row w-full items-center h-full">
          <div className="w-20 mr-auto self-center flex-auto mt-5">
            <h3
              className={classNames(
                'font-bold text-md mb-0 desktop:relative desktop:-mt-4',
                {
                  underline: isNameHyperlinked,
                }
              )}
            >
              {isNameHyperlinked ? (
                <Link
                  noLinkStyles
                  to={`/domui/profile/${profile?.id}`}
                  onClick={() => storePreviousPagePath(clubRosterPath)}
                >
                  {`${profile?.firstName ? profile?.firstName : ''} ${
                    profile?.middleName ? profile?.middleName : ''
                  } ${profile?.lastName ? profile?.lastName : ''}`}
                </Link>
              ) : (
                `${profile?.firstName ? profile?.firstName : ''} ${
                  profile?.middleName ? profile?.middleName : ''
                } ${profile?.lastName ? profile?.lastName : ''}`
              )}
            </h3>
            <p className="text-xs text-gray-400 mb-0 mt-2">
              {profile?.localizedName}
            </p>
            {/* {isMemberIdShown && ( */}
            <p className="text-xs mb-0">
              {t('metadata.label.members.memberid', 'ID: {{ individualId }}', {
                individualId: nfId,
              })}
            </p>
            {/* )} */}
            {/* {isTerminatedAndHasActiveMembershipInOtherClubs && (
              <ClubsList clubs={activeAssociatedClubs} />
            )} */}
          </div>

          {/* {((!isTerminatedFiltered && !isDeceasedFiltered) ||
            !terminationHistory) && (
            <div className="flex flex-col self-center flex-1 text-xs leading-6 w-24 mr-4">
              {clubLeadershipInfo.length ? (
                clubLeadershipInfo.map((role, index) => (
                  <RoleAndTypeLabel
                    key={`${index + role.roleName}`}
                    type={type}
                    role={role}
                    dates={dates}
                    wrapperClasses="mb-2"
                  />
                ))
              ) : (
                <RoleAndTypeLabel type={type} dates={dates} />
              )}
              {membershipsFromThisClub.map((clubMember, index) => {
                if (
                  clubMember.clubId === clubId &&
                  clubMember.terminationDate
                ) {
                  const currentDate = moment(
                    moment().format(CURRENT_DATE_FORMAT)
                  );
                  const terminationDate = moment(clubMember.terminationDate);

                  if (terminationDate.isSameOrAfter(currentDate)) {
                    return (
                      <span
                        key={`${index + clubId}`}
                        className="text-xs text-gray-400"
                      >
                        {t(
                          'roster-result.member.termination-future-termination',
                          'Terminated as of {{ date }}',
                          {
                            date: terminationDate.format(
                              TERMINATION_DATE_FORMAT
                            ),
                          }
                        )}
                      </span>
                    );
                  }
                }
                return null;
              })}
            </div>
          )} */}

          {/* {terminationHistory && !clubLeadershipInfo.length && ( */}
          <div className="flex flex-col self-center flex-1 text-xs leading-6 w-24 mr-4">
            {/* {isDeceasedOutsideOfClub && (
              <p className="text-xs mb-0 pr-5">
                -{' '}
                {t(
                  'roster-result.member.termination-reason-deceased',
                  'Deceased'
                )}
              </p>
            )} */}
            {roles && (
              <p className="text-xs mb-0">
                {t(
                  'roster-result.member.termination-history-active-membership',
                  '{{ type }} {{ date }}-present',
                  {
                    type: mapRoleLabelForRotaractors(t, roles?.role),
                    date: new Date(roles?.startDate).getFullYear(),
                  }
                )}
              </p>
            )}
            {/* {terminationHistory.map(item => (
                <>
                  <p className="text-xs mb-0 mt-3">{item.terminationDates}</p>
                  <p className="text-xs mb-0 pl-2">{item.terminationReason}</p>
                </>
              ))} */}
          </div>
          {/* )} */}
          <div className="flex flex-col self-center flex-1 text-xs leading-6 w-16 mr-4">
            {primaryEmail ? (
              <p className="text-xs mb-0 truncate">
                <a href={`mailto:${primaryEmail}`}>{primaryEmail}</a>
              </p>
            ) : (
              <p className="text-xs mb-0" />
            )}
            {primaryPhone ? (
              <p className="text-xs mb-0 text-gray-600 truncate">
                <a href={`tel:${primaryPhone}`}>{primaryPhone}</a>
              </p>
            ) : (
              <p className="text-xs mb-0" />
            )}
          </div>
          {/* {contextMenuItems.length > 0 ? (
            <ContextMenu
              title={name}
              className="self-center"
              items={contextMenuItems}
              passThrough={{
                clubType,
                individualId: id,
                memberName: name,
                membershipType: type,
                admissionDate,
                backUrl: getClubMembersPath(clubId),
                activeLeaders,
              }}
            />
          ) : (
            <div className="relative ml-auto mr-10 self-center" />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default RosterResultDesktop;
