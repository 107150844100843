import React from 'react';

import OptionItem from '@components/Formik/MultiSelectFilter/OptionItem';

import { OptionProps, OptionTypeBase } from '@external/react-select';

const OptionComponent = (optionProps: OptionProps<OptionTypeBase>) => {
  const { label } = optionProps;

  return (
    <OptionItem optionProps={optionProps}>
      <div className="text-gray-600">
        {label.split('\n').map(str => (
          <div key={str}>{str}</div>
        ))}
      </div>
    </OptionItem>
  );
};

export default OptionComponent;
