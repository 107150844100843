import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Authorizer from '@domui-utils/workflow/authorizer';

// Custom hook to handle API calls
export const useFetchClubPermission = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DynamicData>(null);

  const [error, setError] = useState<DomuiError>(null);

  const fetchClubPermission = async (dominoId: string, data: DynamicData) => {
    setLoading(true);
    setError(null); // Clear error before new request

    try {
      const wfService = new Authorizer();
      const response = await wfService.fetchClubPermission(dominoId, data);
      setData(response);
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, fetchClubPermission };
};
