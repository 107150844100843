import { CSSProperties } from 'react';

import tailwind from '@styles/tailwind';

import { StylesConfig } from '@external/react-select';

import { MultiSelectFilterState } from './types';

const getOptionBg = ({
  isSelected,
  isMulti,
  isFocused,
}: MultiSelectFilterState) => {
  if (isSelected && !isMulti)
    return tailwind.theme.colors['bright-blue']['600'];

  if (isFocused) return tailwind.theme.colors.gray['200'];

  return tailwind.theme.colors.white;
};

export const customStyles: StylesConfig = {
  container: (provided: CSSProperties, state: MultiSelectFilterState) => ({
    ...provided,
    width: `${
      state.selectProps.windowWidth <
      parseInt(tailwind.theme.screens['mobile-l'], 10)
        ? '100%'
        : 'auto;'
    }`,
    position: 'absolute',
    zIndex: 1000,
  }),
  control: (provided: CSSProperties) => ({
    ...provided,
    padding: '0.1875rem 0',
    minWidth: 280,
    borderColor: `${tailwind.theme.colors.gray['300']} !important`,
    borderRadius: 0,
    boxShadow: 'unset',
    '&:hover': {
      boxShadow: 'unset',
    },
  }),
  menu: (provided: CSSProperties) => ({
    ...provided,
    marginTop: 0,
    border: `1px solid ${tailwind.theme.colors.gray['300']}`,
    borderTopWidth: 0,
    borderRadius: '0 0 4px 4px',
    boxShadow: 'unset !important',
  }),
  menuList: (provided: CSSProperties, state: MultiSelectFilterState) => ({
    ...provided,
    border: 0,
    maxHeight: `${
      state.selectProps.windowWidth <
      parseInt(tailwind.theme.screens['mobile-l'], 10)
        ? '300px'
        : '400px'
    }`,
  }),
  placeholder: (provided: CSSProperties) => ({
    ...provided,
    paddingLeft: '14px !important',
  }),
  input: (provided: CSSProperties, state: MultiSelectFilterState) => ({
    ...provided,
    background: state.isDisabled
      ? tailwind.theme.colors.gray['100']
      : tailwind.theme.colors.white,
    width: '100%',
    margin: 4,
    '& > div': {
      display: 'block !important',
    },
    '& input': {
      boxSizing: 'border-box !important',
      height: 40,
      width: '100% !important',
      paddingLeft: '12px !important',
      border: `1px solid ${tailwind.theme.colors.gray['300']}!important`,
      outlineWidth: '0px !important',
      boxShadow: 'none !important',
    },
    '& input:focus': {
      boxShadow: 'none !important',
      outlineWidth: '0px !important',
    },
  }),
  option: (provided: CSSProperties, state: MultiSelectFilterState) => ({
    ...provided,
    background: getOptionBg(state),
    color:
      state.isSelected && state.isMulti && tailwind.theme.colors.gray['300'],
    fontSize: 14,
    cursor: 'pointer',
  }),
};
