import React from 'react';

const Dropdown: React.FC<{
  children: JSX.Element;
  isOpen: boolean;
  target: JSX.Element;
  onClose: () => void;
}> = ({ children, isOpen, target, onClose }) => (
  <div className="relative">
    {target}
    {isOpen && <div className="fixed inset-0 z-10" onClick={onClose} />}
    {isOpen && <div className="z-2">{children}</div>}
  </div>
);

export default Dropdown;
