/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

// import { findIndex, orderBy, some } from 'lodash';
// import moment from 'moment';

// import {
//   AssignmentInfo,
//   getActiveAssociatedClub,
//   getContextMenuItems,
//   getMembershipDates,
//   getMembershipYearsLabel,
//   LeadsMMLPage,
//   mapRoleLabelForRotaractors,
// } from '../utils';
import RosterResultRow from './RosterResultRow';

// import {
//   HISTORY_PAST_YEARS,
//   RolesForSelectClubOfficers,
//   ROTARACT_ADVISOR,
// } from '@domain/clubs';

// import { getYear, isActiveDateRange } from '@utils/datetime';
// import { isActiveLeadership } from '@utils/leadershipUtils';
// import { isActiveMembership } from '@utils/membershipUtils';

import { useTranslation } from '@external/react-i18next';

type RosterResultRowFragment = {
  clubId: string;
  nfId: string;
  profile: {
    firstName: string;
    id: string;
    lastName: string;
    middleName: string;
    photoUri: string;
    localizedName: string;
  };
  email: {
    length: number;
    find: any;
    email: string;
    isPrimary: boolean;
    type: string;
  };
  phone: {
    length: number;
    find: any;
    isFax: boolean;
    isPrimary: boolean;
    phone: string;
    type: string;
  };
  roles: {
    length: number;
    find: any;
    endDate: string;
    role: string;
    startDate: string;
  };
  isHonorary: boolean;
  status: string;
  selectMember: (id: string) => void;
};

// export interface IRosterResult
//   extends RosterResultRowFragment,
//     RosterResultType {}

const RosterResult: React.FC<RosterResultRowFragment> = props => {
  const {
    // clubId,
    // clubType,
    // appliedRoleFilter,
    // appliedStatusFilter,
    // membershipId,
    // isLeadershipRole,
    // id,
    // clubLeadership,
    // clubMemberships,
    // leadershipsFromThisClub,
    // isDeceased,
    // deceasedDate,
    // activeMembershipInAllDistricts,
    // associatedClubsInfo,
    // operationsAccess,
    // isLeads,
    // isThreeColumn,

    clubId,
    profile,
    email,
    phone,
    roles,
    nfId,
    // isHonorary,
    // status,
    // selectMember,
  } = props;
  // const { t } = useTranslation();

  const primaryEmail: any = email?.find((item: any) => {
    if (item) {
      return item;
    }
  });

  const primaryPhone: any = phone?.find((item: any) => {
    if (item) {
      return item;
    }
  });

  const rolesValue: any = roles?.find((item: any) => {
    if (item) {
      return item;
    }
  });

  // const getClubLeadershipInfo = () => {
  //   let assignments: AssignmentInfo[] = [];

  //   // If filter is applied => return leadership even if it is not active
  //   if (appliedRoleFilter.length && roles?.length) {
  //     const filteredLeaderships = clubLeadership.filter(({ role }) =>
  //       appliedRoleFilter.includes(role)
  //     );

  //     assignments = filteredLeaderships.reduce(
  //       (acc: AssignmentInfo[], leadership) => {
  //         const { role, startDate, endDate } = leadership;
  //         const roleTerms = getMembershipYearsLabel(startDate, endDate);
  //         const roleIndex = findIndex(acc, { roleName: role });

  //         if (roleIndex > -1) {
  //           acc[roleIndex].terms.push(roleTerms);
  //         } else {
  //           acc.push({ roleName: role, terms: [roleTerms] });
  //         }

  //         return acc;
  //       },
  //       []
  //     );
  //   } else {
  //     // If filter is not applied => return leadership only if it is active
  //     leadershipsFromThisClub.forEach(({ role, startDate, endDate }) => {
  //       if (isActiveDateRange(startDate, endDate)) {
  //         assignments.push({
  //           roleName: role,
  //           terms: [getMembershipYearsLabel(startDate, endDate)],
  //         });
  //       }
  //     });
  //   }

  //   return assignments;
  // };

  // const getActiveLeadership = () => {
  //   if (clubLeadership.length)
  //     return clubLeadership.filter(item => isActiveLeadership(item))[0];
  //   return null;
  // };

  // const clubLeadershipInfo = getClubLeadershipInfo();

  // const isTerminatedFiltered = appliedStatusFilter?.includes(
  //   MembershipStatusFilter.Terminated
  // );

  // const isDeceasedFiltered = appliedStatusFilter?.includes(
  //   MembershipStatusFilter.Deceased
  // );

  // const activeMembership = clubMemberships.find(
  //   ({ admissionDate, terminationDate }) =>
  //     isActiveMembership({ admissionDate, terminationDate })
  // );
  // const type = activeMembership?.type;
  // const admissionDate = activeMembership?.admissionDate;

  // const activeAssociatedClubs = getActiveAssociatedClub(
  //   associatedClubsInfo,
  //   clubId,
  //   activeMembershipInAllDistricts
  // );

  // const activeLeadership = getActiveLeadership();

  // const isNonMember =
  //   clubMemberships.length === 0 &&
  //   some(
  //     clubLeadershipInfo,
  //     ({ roleName }) =>
  //       roleName ===
  //         RolesForSelectClubOfficers.ClubExecutiveSecretaryOrDirector ||
  //       roleName === ROTARACT_ADVISOR
  //   );

  // const getActiveLeaderships = () => {
  //   if (clubLeadership.length)
  //     return clubLeadership.filter(item => isActiveLeadership(item));
  //   return [];
  // };

  // const activeLeaders = getActiveLeaderships();

  // const contextMenuItems = getContextMenuItems(t, {
  //   id,
  //   isDeceased,
  //   activeMembership,
  //   clubId,
  //   membershipId,
  //   operationsAccess,
  //   clubType,
  //   leadershipId: activeLeadership?.id,
  //   roleId: activeLeadership?.roleId,
  //   startDate: activeLeadership?.startDate,
  //   endDate: activeLeadership?.endDate,
  //   // isNonMember,
  //   activeLeaders,
  // });

  // const dates = getMembershipDates(
  //   !!clubLeadershipInfo.length,
  //   t,
  //   admissionDate,
  //   deceasedDate
  // );

  // const formattedDeceasedDate = moment(deceasedDate).format('YYYY-MM-DD');
  // const todayISO = new Date().toISOString();
  // const terminationDateISO = `${getYear(todayISO) -
  //   HISTORY_PAST_YEARS.TERMINATION}-07-01T00:00:00Z`;

  // const terminationHistory =
  //   isTerminatedFiltered || isDeceasedFiltered
  //     ? orderBy(
  //         clubMemberships.filter(
  //           item =>
  //             item.terminationDate &&
  //             moment(item.terminationDate).isSameOrAfter(terminationDateISO)
  //         ),
  //         'terminationDate',
  //         'desc'
  //       ).map(item => {
  //         const startDate = new Date(item.admissionDate).getFullYear();
  //         const endDate =
  //           item.terminationDate &&
  //           new Date(item.terminationDate).getFullYear();
  //         return {
  //           terminationDates: `- ${mapRoleLabelForRotaractors(
  //             t,
  //             item.type
  //           )} ${startDate}-${endDate}`,
  //           terminationReason:
  //             item.terminationDate === formattedDeceasedDate
  //               ? t(
  //                   'roster-result.member.termination-reason-deceased',
  //                   'Deceased'
  //                 )
  //               : t(
  //                   'roster-result.member.termination-reason-terminated',
  //                   'Terminated'
  //                 ),
  //         };
  //       })
  //     : null;

  // const isDeceasedOutsideOfClub =
  //   isDeceased &&
  //   clubMemberships.every(
  //     membership => membership.terminationDate !== formattedDeceasedDate
  //   );

  // const hasActiveMembershipInOtherClubs = Boolean(
  //   activeMembershipInAllDistricts
  // );
  // const isTerminatedAndHasActiveMembershipInOtherClubs =
  //   isTerminatedFiltered && hasActiveMembershipInOtherClubs;

  return (
    <RosterResultRow
      {...props}
      // contextMenuItems={contextMenuItems}
      // activeAssociatedClubs={activeAssociatedClubs}
      // type={type}
      // isDeceasedFiltered={isDeceasedFiltered}
      // isTerminatedFiltered={isTerminatedFiltered}
      // isLeadershipRole={isLeadershipRole}
      // dates={dates}
      // terminationHistory={terminationHistory}
      // isDeceasedOutsideOfClub={isDeceasedOutsideOfClub}
      // hasActiveMembershipInOtherClubs={hasActiveMembershipInOtherClubs}
      // isTerminatedAndHasActiveMembershipInOtherClubs={
      //   isTerminatedAndHasActiveMembershipInOtherClubs
      // }
      // clubLeadershipInfo={clubLeadershipInfo}
      // activeMembership={activeMembership}
      // admissionDate={admissionDate}
      // isNonMember={isNonMember}
      // activeLeaders={activeLeaders}
      // isLeads={isLeads}
      // isThreeColumn={isThreeColumn}
      clubId={clubId}
      profile={profile}
      email={primaryEmail}
      phone={primaryPhone}
      roles={rolesValue}
      nfId={nfId}
      // isHonorary={isHonorary}
      // status={status}
      // selectMember={selectMember}
    />
  );
};

export default RosterResult;
