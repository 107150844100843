import React from 'react';

import { Button } from '@components/Button';

import { useTranslation } from '@external/react-i18next';
import {
  components,
  MenuListComponentProps,
  OptionTypeBase,
} from '@external/react-select';

const MenuList = ({
  children,
  ...menuProps
}: MenuListComponentProps<OptionTypeBase>) => {
  const { t } = useTranslation();
  const {
    handleApplyFilter,
    applyBtnLabel: { key, name },
    menuRef,
  } = menuProps.selectProps;

  return (
    <div ref={menuRef}>
      <components.MenuList {...menuProps}>{children}</components.MenuList>
      <hr />
      <div className="m-3">
        <Button
          clickHandler={handleApplyFilter}
          small
          className="tablet:w-full"
          dataTestId="apply-button"
          type="button"
        >
          {t(key, name)}
        </Button>
      </div>
    </div>
  );
};

export default MenuList;
