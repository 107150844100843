import React from 'react';

// import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { curryRight } from 'lodash';

// import SingleSelectFilter from '@components/Formik/SingleSelectFilter';
// import { isSomeFiltersApplied } from '@use-cases/districts';

import MultiSelectFilter from '@domui-components/Formik/MultiSelectFilter';
import {
  CLUB_ROSTER_FILTERS,
  // ClubRosterFilterProps,
} from '@domui-domain/clubs';

import { useTranslation } from '@external/react-i18next';

interface Option {
  id: string;
  value: string;
  label: string;
}

type filter = {
  roles: Option[];
  memberTypes: Option[];
};

interface Props {
  multiSelectOptions: {
    roles: { id: string; type: string; label: string; value: string }[];
    memberTypes: { id: string; value: string; label: string }[];
  };
  initialFilterValues: filter;
  applyFilter: (selectedOptions: Option[], name: CLUB_ROSTER_FILTERS) => void;
  resetFilter: () => void;
  filtersListToShow: CLUB_ROSTER_FILTERS[];
}

// interface Props extends ClubRosterFilterProps {
//   filtersListToShow: string[];
// }

const Filters: React.FC<Props> = ({
  multiSelectOptions,
  initialFilterValues,
  applyFilter,
  resetFilter,
  filtersListToShow,
}) => {
  const { t } = useTranslation();

  // const isFilterApplied = isSomeFiltersApplied(initialFilterValues);

  const handleApplyFilter = (filterName: CLUB_ROSTER_FILTERS) =>
    curryRight(applyFilter)(filterName);

  return (
    <Formik
      enableReinitialize
      initialValues={initialFilterValues}
      onSubmit={() => {}}
    >
      <Form className="district-members__filters desktop:flex">
        {filtersListToShow.includes(CLUB_ROSTER_FILTERS.MEMBER_ROLES) && (
          <div className="px-5 desktop:px-3">
            <MultiSelectFilter
              title={t('club-members.filters-club-role-label', 'Role')}
              name={CLUB_ROSTER_FILTERS.MEMBER_ROLES}
              inputPlaceholder={t(
                'club-members.search-club-role-placeholder',
                'Search by Club Role'
              )}
              resetFilter={resetFilter}
              applyFilter={handleApplyFilter(CLUB_ROSTER_FILTERS.MEMBER_ROLES)}
              options={multiSelectOptions?.roles}
            />
          </div>
        )}
        {filtersListToShow.includes(CLUB_ROSTER_FILTERS.MEMBERSHIP_TYPES) && (
          <div className="px-5 desktop:px-3">
            <MultiSelectFilter
              title={t(
                'club-members.filters-member-status-label',
                'Member Status'
              )}
              name={CLUB_ROSTER_FILTERS.MEMBERSHIP_TYPES}
              inputPlaceholder={t(
                'club-members.filters-member-status-search-placeholder',
                'Filter by Member Status'
              )}
              resetFilter={resetFilter}
              applyFilter={handleApplyFilter(
                CLUB_ROSTER_FILTERS.MEMBERSHIP_TYPES
              )}
              options={multiSelectOptions?.memberTypes}
              isSearchable={false}
            />
          </div>
        )}

        {/* {filtersListToShow.includes(CLUB_ROSTER_FILTERS.COUNTRIES_REGIONS) && (
          <div className="px-5 desktop:px-3">
            <MultiSelectFilter
              title={t(
                'add-member.identify-member-form.filters-region-label',
                'Country/Region'
              )}
              name={CLUB_ROSTER_FILTERS.COUNTRIES_REGIONS}
              inputPlaceholder={t(
                'add-member.identify-member-form.filters-region-placeholder',
                'Filter by Country/Region'
              )}
              resetFilter={resetFilter}
              applyFilter={handleApplyFilter(
                CLUB_ROSTER_FILTERS.COUNTRIES_REGIONS
              )}
              options={multiSelectOptions.countriesRegions}
            />
          </div>
        )} */}
        {/* {filtersListToShow.includes(CLUB_ROSTER_FILTERS.CLUB_TYPES) && (
          <div className="px-5 desktop:px-3">
            <SingleSelectFilter
              title={t(
                'add-member.identify-member-form.filters-club-type-label',
                'Club Type'
              )}
              name={CLUB_ROSTER_FILTERS.CLUB_TYPES}
              inputPlaceholder={t(
                'add-member.identify-member-form.filters-club-type-placeholder',
                'Filter by Club Type'
              )}
              resetFilter={resetFilter}
              applyFilter={handleApplyFilter(CLUB_ROSTER_FILTERS.CLUB_TYPES)}
              options={multiSelectOptions.clubTypes}
            />
          </div>
        )} */}
        {/* {filtersListToShow.includes(CLUB_ROSTER_FILTERS.CLUBS) && (
          <div className="px-5 desktop:px-3">
            <MultiSelectFilter
              title={t(
                'add-member.identify-member-form.filters-club-label',
                'Club'
              )}
              name={CLUB_ROSTER_FILTERS.CLUBS}
              inputPlaceholder={t(
                'add-member.identify-member-form.filters-club-placeholder',
                'Filter by Club'
              )}
              resetFilter={resetFilter}
              applyFilter={handleApplyFilter(CLUB_ROSTER_FILTERS.CLUBS)}
              options={multiSelectOptions.clubs}
            />
          </div>
        )} */}
        {/* {filtersListToShow.includes(CLUB_ROSTER_FILTERS.COUNTRIES_REGIONS) &&
          filtersListToShow.includes(CLUB_ROSTER_FILTERS.CLUBS) && (
            <button
              type="button"
              className={classNames(
                isFilterApplied ? 'text-dark-blue-400' : 'text-gray-400',
                'text-md leading-6 pl-5 desktop:pl-2'
              )}
              onClick={() => {
                resetFilter();
              }}
              disabled={!isFilterApplied}
            >
              {t('clear-all', 'Clear All')}
            </button>
          )} */}
      </Form>
    </Formik>
  );
};

export default Filters;
