import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Organization from '@domui-utils/workflow/organization';

type clubLeadershipBody = {
  domOrgId: string;
  startDate: string;
  endDate: string;
  pageNumber: number;
  pageSize: number;
};

// Custom hook to handle API calls
export const useFetchClubLeadership = () => {
  const [data, setData] = useState<DynamicData>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);

  const fetchClubLeadership = async (requestBody: clubLeadershipBody) => {
    // Clear error before new request
    const { domOrgId, startDate, endDate, pageNumber, pageSize } = requestBody;
    try {
      if (domOrgId) {
        setLoading(true);
        setError(null);
        const wfService = new Organization();
        const response = await wfService.getClubLeadership(
          domOrgId,
          startDate,
          endDate,
          pageNumber,
          pageSize
        );
        const fetchData = {
          leadership: [],
        };
        const data = response?.status === 404 ? fetchData : response;
        setData(data);
        setLoading(false);
      }
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return { data, loading, error, fetchClubLeadership };
};
