/* eslint-disable @typescript-eslint/no-unused-vars */
import { Moment } from 'moment';

// import { Option } from '@components/Formik/MultiSelectFilter/types';
import { FilterProps } from '@presenters/web/components/DistrictMembersFilters/types';
import { AssignNonMemberProps } from '@presenters/web/pages/Clubs/ManageClubOfficers/AssignNonMemberToRole';
import { SelectOfficerProps } from '@presenters/web/pages/Clubs/ManageClubOfficers/SelectOfficer/SelectOfficer';

import { TermToAssign } from '@domain/districts';

import { AddressType, ClubLeadershipItem, EmailType } from '@typings/graphql';
import {
  BaseAddress,
  Club as ClubType,
  ClubTypeFilterValue,
  Country,
  Exact,
  Fax,
  Language,
  Meeting as MeetingOutput,
  MeetingType,
  MemberDetailsQuery,
  MembershipForAddMember,
  MembershipStatusFilter,
  MembershipType,
  OrganizationBase,
  PhoneNumber,
  PhoneType,
  SearchClubMeetingFragment,
  SearchClubRowFragment,
  UpdateIndividualMutationVariables,
  WeekdayFull,
} from '@typings/operations';

export type MeetingContent = Omit<MeetingOutput, '__typename'>;
export type Maybe<T> = T | null;
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Void: any;
};

export interface ClubMeetingValues {
  name?: string;
  languageId?: string;
  comment?: string;
  weekday?: WeekdayFull;
  frequency?: string;
  time: string;
  type?: MeetingType;
  locationVaries: boolean;
  locationNameOrDetail?: string;
  // flattened address
  // required for dependent validation
  lineOne?: string;
  lineTwo?: string;
  lineThree?: string;
  countryId: string;
  city?: string;
  stateId: string;
  internationalProvince: string;
  postalCode: string;
  // end flattened address
  detailForJoiningRemotely?: string;
  onlineLocation?: string;
  language?: string;
  longitude?: number | null;
  latitude?: number | null;
}

export interface VendorItem {
  name?: string | null;
  startDate?: string | null;
}

export type ContactInformationProps = {
  Address?: Omit<BaseAddress, 'type'> | null;
  // isEditable: boolean;
  editContactInformationLink: string;
  primaryEmail?: string | null;
  phones?: Pick<PhoneNumber, 'number' | 'extension' | 'countryId'> | null;
  primaryFax?: Pick<Fax, 'number' | 'extension' | 'countryId'> | null;
  primaryWebsite?: string | null;
  officialLanguage?: Pick<Language, 'name'> | null;
  isUserLoggedIn?: boolean;
  countries: Country[];
  individualOperationAccess?: string | null;
};

export type EmailOutput = {
  email: string;
  isPrimary: boolean;
  id?: string;
};

export type FormItem =
  | FormEmailItem
  | FormAddressItem
  | FormPhoneItem
  | FormWebsiteItem
  | BasicLanguageInput;

/** Basic Language input */
export type BasicLanguageInput = {
  action?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  languageId?: Scalars['String'];
  __typename?: 'Language';
};
export type WebsiteOutput = {
  primaryWebsite: string;
  otherWebsites?: string;
  id?: string;
};
export type GetClubContactInfoValues = {
  primaryWebsite?: Maybe<Scalars['String']>;
  primaryEmail?: Maybe<Scalars['String']>;
  primaryFax?: Maybe<Fax>;
  phones?: Maybe<PhoneNumber>;
  officialLanguage?: Maybe<Language>;
} & { address: Omit<Address, 'type'> | null } & { email: EmailOutput } & {
  website: WebsiteOutput;
};

export type ContactAddress = {
  country?: string;
  lineOne: string;
  lineTwo?: string;
  lineThree?: string;
  countryId: string;
  city: string;
  state?: string;
  stateId?: string;
  postalCode: string;
  internationalProvince: string;
  hasStates?: boolean;
  id?: string | null;
};
export interface ClubContactInfoFormValues {
  address?: ContactAddress | null;
  primaryEmail?: string;
  primaryWebsite?: string;
  phones?: PhoneNumber[];
  primaryFax?: Fax;
  officialLanguage: BasicLanguageInput;
  email?: EmailOutput;
  website?: WebsiteOutput;
}

export enum RolesForSelectClubOfficers {
  ClubFoundationChair = 'Club Foundation Chair',
  ClubMembershipChair = 'Club Membership Chair',
  ClubPublicImageChair = 'Club Public Image Chair',
  ClubServiceProjectsChair = 'Club Service Projects Chair',
  ClubPresident = 'Club President',
  ClubSecretary = 'Club Secretary',
  ClubTreasurer = 'Club Treasurer',
  ClubExecutiveSecretaryOrDirector = 'Club Executive Secretary/Director',
  ClubExecutiveDirectorAssistant = 'Club Executive Director Assistant',
  ClubRotractAdvisor = 'Rotaract Advisor',
}

export enum RolesForSelectRotaractOfficers {
  RotaractPresident = 'Rotaract President',
  RotaractServiceProjectsChair = 'Rotaract Service Projects Chair',
  RotaractMembershipChair = 'Rotaract Membership Chair',
  RotaractPublicImageChair = 'Rotaract Public Image Chair',
  RotaractTreasurer = 'Rotaract Treasurer',
  RotaractSecretary = 'Rotaract Secretary',
  RotaractFoundationChair = 'Rotaract Foundation Chair',
}

/** A physical location. */
export type AddressInput = {
  /** Name of the city. Latin characters only. Cannot start with a space, dot, comma or dash. Maximum length is 40. */
  city: Scalars['String'];
  /** Name of the country */
  country?: Maybe<Scalars['String']>;
  /** Id of the country as returned by the countries field. */
  countryId: Scalars['String'];
  /** Required if the country IS NOT one of USA, CAN - Must be empty if country IS one of above */
  internationalProvince?: Maybe<Scalars['String']>;
  /** Should not start with a space, dot, comma or dash. Must be Latin characters (Aa-Zz) and numbers, underscores, periods, and hyphens only. Length between 2 and 40. */
  lineOne: Scalars['String'];
  /** Must be empty if LineTwo is empty. Should not start with a space, dot, comma or dash. Must be Latin characters (Aa-Zz) and numbers, underscores, periods, and hyphens only. Maximum length is 40. */
  lineThree?: Maybe<Scalars['String']>;
  /** Should not start with a space, dot, comma or dash. Must be Latin characters (Aa-Zz) and numbers, underscores, periods, and hyphens only. Maximum length is 40. */
  lineTwo?: Maybe<Scalars['String']>;
  /** Required if the countryId IS one of USA, CAN, GUM, MSM, PRI, VIR, ASM, MHL, MNP, PLW. Otherwise, it's optional. Maximum length is 20. */
  postalCode?: Maybe<Scalars['String']>;
  /** State ID (as returned by the states field) for USA and Canada. */
  state?: Maybe<Scalars['String']>;
  /** Required if the country IS one of USA, CAN, and it must belongs to the country - Must be empty if country IS NOT one of above */
  stateId?: Maybe<Scalars['String']>;
  /** Address type. One of Business, Home, Localized, Project, HostCountry. Defaults to home. */
  type?: Maybe<AddressType>;
};

export type UpdateContactInformationMutationVariables = Exact<{
  id: Scalars['String'];

  primaryEmail: Array<EmailInput>;

  primaryAddress?: Maybe<AddressInput>;

  primaryPhone?: Array<Maybe<IndividualPhoneInput>>;

  websites: Array<WebsiteInput>;

  officialLanguage: Array<BasicLanguageInput>;
}>;

export type ContactInformationFormValues = {
  emails: Array<FormEmailItem>;
  addresses: Array<FormAddressItem>;
  phones: Array<FormPhoneItem>;
  websites: Array<WebsiteOutput>;
  officialLanguage: Array<BasicLanguageOutput>;
};

export type WebsiteInput = {
  action?: Maybe<Scalars['String']>;
  /** Website identifier */
  id?: Maybe<Scalars['String']>;
  /** Other club websites */
  otherWebsites?: Maybe<Scalars['String']>;
  /** club primary website */
  primaryWebsite?: Maybe<Scalars['String']>;
};

export type BasicLanguageOutput = {
  id?: string;
  languageId?: string;
};

/** An email address with metadata. */
export type EmailInput = {
  /** The email address. */
  email: Scalars['String'];

  /** Email type. One of Personal, Business. If not provided, set to Personal. */
  type: EmailType;
};

export type Email = {
  __typename?: 'Email';
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  /** Only vailable if _this_ individual is currently logged in into the app. Otherwise it's null. */
  isSignin?: Maybe<Scalars['Boolean']>;
  type: EmailType;
};

export type IndividualPhoneInput = {
  /** Id of the country as returned by the countries field. */
  countryId: Scalars['String'];
  /** Only allows digits, spaces, dashs, slashes, commas parentheses and dots Can ONLY start with either a numeric or open parentheses. Maximum length is 10. */
  extension?: Maybe<Scalars['String']>;
  /** Only allows digits, spaces, dashs, slashes, commas parentheses and dots. Can ONLY start with either a numeric or open parentheses. Number/extension/type combination must not already exist for this individual. Duplicate checking must remove all non-numeric characters. Length limit is between 1 and 30. */
  number: Scalars['String'];
  /** Phone type. Must be one of Business, Home, Mobile. If not provided, set to Mobile. */
  type: PhoneType;
  isFax: boolean;
};

export type FormEmailItem = Email & {
  isPrimary: boolean;
  id?: string | null;
  action?: string;
};
type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  internationalProvince?: Maybe<Scalars['String']>;
  lineOne?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  /** State/Province/Territory for countries other than USA and Canada. */
  state?: Maybe<Scalars['String']>;
  /** State ID (as returned by the states field) for USA and Canada. */
  stateId?: Maybe<Scalars['String']>;
  type: AddressType;
};

export type FormAddressItem = Address & {
  isPrimary: boolean;
  hasStates?: boolean;
  action?: string;
};

export type FormWebsiteItem = WebsiteOutput & {
  id?: string | null;
  action?: string;
  __typename?: 'Website';
};

export type FormPhoneItem = PhoneNumber & {
  isPrimary: boolean;
  isFax: boolean;

  action?: string;
};

export enum ClubsPrefix {
  RotaryPrefix = 'Club',
  RotaractPrefix = 'Rotaract',
}

export type AssignMemberToRoleValues = Partial<
  SelectOfficerProps & AssignNonMemberProps & ConfirmOfficerValues
>;

export type Period = {
  start: Moment;
  end: Moment;
};

export type Entity = {
  id: string;
  name: string;
};

export type Club = {
  id: string;
  name: string;
  clubType: string;
  riClubId: string;
};

export type Individual = Entity & {
  photoUri?: string | null;
  email?: string | null;
  membershipAdmissionDate?: string | null;
  membershipTerminationDate?: string | null;
  otherEmails?: string[] | null;
  onlineId?: string | null;
  riIndividualId?: string | null;
  preferredLanguage?: string | null;
};

export type UpdateIndividual = Entity &
  MemberDetailsOutput & {
    photoUri?: string | null;
    email?: string | null;
    membershipAdmissionDate?: string | null;
    membershipTerminationDate?: string | null;
    otherEmails?: string[] | null;
    onlineId?: string | null;
  };

export type SelectRoleValues = {
  type: MembershipType;
  club: Entity;
  individual: Individual;
  role?: Entity;
  slot?: Period;
  customSlot?: Period | null;
  selectedTerm?: TermToAssign;
  email?: string;
  isMemberRow?: boolean;
  isReplaceNonMemberFlowEligible?: boolean;
};

export type ConfirmOfficerValues = {
  club: Entity;
  role: Entity;
  slot: Period;
  individual: Individual;
  editIndividual?: UpdateIndividual;
  customSlot?: Period | null;
  email?: string;
  individualToReplace?: UpdateIndividual;
  effectiveDate?: Date | Moment | string | null;
};

export type ReplaceRoleToMemberValues = {
  type: MembershipType;
  club: Entity;
  individual: Individual;
  role?: Entity;
  slot?: Period;
  customSlot?: Period | null;
  selectedTerm?: TermToAssign;
  email?: string;
  isMemberRow?: boolean;
  individualToReplace?: (Entity & Pick<Individual, 'email'>) | null;
  effectiveDate?: Date | Moment | string | null;
  leadershipId?: string | null;
  activeLeaders: ActiveLeaders[];
};

/**
 * Properties that are passed down to every wizard page.
 */
export type TerminateMembershipPageProps = {
  clubId: string;
  clubType: string;
  memberName: string;
  membershipType: string;
  admissionDate: string;
  dirty?: boolean;
  initialTouched?: boolean;
};

/**
 * All form properties collected in this wizard.
 */
export type TerminateMembershipValues = {
  terminationDate?: Date;
  terminationReason: string;
  terminationConfirmation: boolean;
};

export type ExtendedMemberDetails = Omit<
  MemberDetailsQuery['addMemberGetIndividual']['individual'],
  'yearOfBirth' | 'activeFullMemberships'
> & { primaryAddress: { hasStates?: boolean } };

export type MemberDetailsInput = ExtendedMemberDetails & {
  yearOfBirth: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  localizedName: string;
  gender: string;
  suffix?: string;
  prefix?: string;
  hasActiveMembership: boolean;
  recentMemberships: MembershipForAddMember[];
  id?: string;
};

export type MemberDetailsFormValues = MemberDetailsInput & {
  dayOfBirth: string;
  monthOfBirth: string;
};

export type MemberDetailsOutput = Omit<
  UpdateIndividualMutationVariables,
  'individualId'
>;

export interface IdentificationParameters {
  name?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  id?: number | null;
  region?: string[];
  riClubId?: string[];
  clubTypes?: string[];
}

export type MembershipStatusOption = {
  value: MembershipStatusFilter;
  label: string;
  id: string;
};

export type ClubMembersList = Omit<
  MemberDetailsQuery['addMemberGetIndividual'],
  ''
>;

export interface MembershipData {
  memberType: string;
  admissionDate: string;
  sponsoredBy?: string[];
}

export interface FormMembershipData {
  memberType?: string;
  admissionDate?: string;
  sponsoredBy?: string[];
  procedureCertification: boolean;
}

export type MemberSearchFormValues = {
  name?: string;
  email?: string;
  location?: string;
  city?: string;
  country?: string;
  stateId?: string;
  district?: string;
  club?: string;
  districtRole?: string;
  districtRoles?: string[];
  clubRole?: string;
  clubRoles?: string[];
  memberClubType?: string;
  memberTypes?: string;
};

export enum MEMBER_SEARCH_FILTERS {
  NAME = 'name',
  EMAIL = 'email',
  LOCATION = 'location',
  CITY = 'city',
  COUNTRY = 'country',
  STATE_ID = 'stateId',
  DISTRICT = 'district',
  CLUB = 'club',
  DISTRICT_ROLE = 'districtRole',
  DISTRICT_ROLES = 'districtRoles',
  CLUB_ROLE = 'clubRole',
  CLUB_ROLES = 'clubRoles',
  MEMBER_CLUB_TYPE = 'memberClubType',
  MEMBER_TYPES = 'memberTypes',
}

export type SiteSearchFormValues = {
  keywords?: string;
  contentType?: string;
  documentMimeType?: string;
  langCode?: string;
  topic?: string;
};

export enum SITE_SEARCH_FILTERS {
  KEYWORDS = 'keywords',
  CONTENT_TYPE = 'contentType',
  DOCUMENT_MIMETYPE = 'documentMimeType',
  LANGCODE = 'langcode',
  TOPIC = 'topic',
}

export type ClubSearchFormValues = {
  clubName?: string;
  clubType?: ClubTypeFilterValue;
  weekday?: string;
  meetingType?: string;
  meetingLang?: string;
  meetingLocation?: string;
  meetingLocationLatitude?: number;
  meetingLocationLongitude?: number;
  meetingLocationRange?: number;
  distance?: string;
  distanceUnits?: string;
  advanced?: boolean;
  organizationBase?: OrganizationBase;
};

export enum CLUB_SEARCH_FILTERS {
  CLUB_NAME = 'clubName',
  CLUB_TYPE = 'clubType',
  WEEK_DAY = 'weekday',
  MEETING_TYPE = 'meetingType',
  MEETING_LANG = 'meetingLang',
  MEETING_LOCATION = 'meetingLocation',
  MEETING_LOCATION_LATITUDE = 'meetingLocationLatitude',
  MEETING_LOCATION_LONGITUDE = 'meetingLocationLongitude',
  MEETING_LOCATION_RANGE = 'meetingLocationRange',
  DISTANCE = 'distance',
  DISTANCE_UNITS = 'distanceUnits',
  ORGANIZATIONBASE = 'organizationBase',
}

export type LatLngLiteral = google.maps.LatLngLiteral;

export interface SearchClubNumberedMeetingFragment
  extends SearchClubMeetingFragment {
  xRiLocationNumber?: number;
  comment?: null | string[];
  url?: null | string;
}

export interface SearchClubRowFragmentWithNumberedMeetings
  extends SearchClubRowFragment {
  meetings: SearchClubNumberedMeetingFragment[];
}

export interface MeetingLocationCounter {
  nextNumber: number;
}

export enum CLUB_ROSTER_FILTERS {
  MEMBER_ROLES = 'roles',
  MEMBERSHIP_TYPES = 'memberTypes',
  // COUNTRIES_REGIONS = 'countriesRegions',
  // CLUBS = 'clubs',
  // CLUB_TYPES = 'clubTypes',
}

export enum AddOfficerSteps {
  IDENTIFY,
  INFO,
  MEMBERSHIP,
}

export enum ReplaceOfficerSteps {
  IDENTIFY,
  INFO,
  MEMBERSHIP,
}

export interface Option {
  id: string;
  value: string;
  label: string;
}

export type ClubRosterFilter = {
  memberTypes?: Option[];
  roles?: Option[];
  countriesRegions?: Option[];
  clubs?: Option[];
  clubTypes?: Option[];
};

export type ClubRosterFilterProps = FilterProps<
  CLUB_ROSTER_FILTERS,
  ClubRosterFilter
>;

export type UnassignedRole = {
  id: string;
  name: string;
  mandatory: boolean;
  term?: {
    startDate: string;
    endDate: string;
  };
};

export type SearchFormValues = {
  memberId: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type RotaryRotaractRolesMapping = {
  [key: string]: string[];
};

export type ClubTypeMapping = {
  [key: string]: string;
};

export enum CLUB_TABS {
  DETAILS = 'details',
  MEMBERS = 'members',
  FINANCES = 'finances',
  REPORTS = 'reports',
  GOALS = 'goals',
  MANAGE_MEMBERSHIP = 'manageMember',
}

export type ActiveLeaders = {
  __typename?: 'Leadership' | undefined;
  endDate: string;
  id: string;
  role: string;
  roleId: string;
  startDate: string;
};

export enum SITECORE_USER_TYPES {
  UNAUTHENTICATED = 'unauthenticated',
  NON_MEMBER = 'non-member',
  MEMBER = 'member',
  LEADER = 'leader',
  USER_LOADING = 'unavailable',
}

export type ClubLeadersContextType = Array<ClubLeadershipItem>;
