import { useState } from 'react';

import Organization from '../utils/workflow/organization';

import { useNotifications } from '@use-cases/notifications';

import { localizedNavigate } from '@utils/localized-navigate';
import { getChannel } from '@utils/sendMessage';

import { DomuiError, DynamicData } from '@domui-domain/type';

import { useTranslation } from '@external/react-i18next';

// Custom hook to handle API calls
export const useUpdateClubContactInfo = () => {
  const [data, setData] = useState<DynamicData>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<DomuiError>(null);
  const { addSuccess } = useNotifications();
  const { t } = useTranslation();

  const updateClubContactInfo = async (
    orgId: string,
    updateData: DynamicData,
    clubDetailsPath: string
  ) => {
    try {
      if (updateData) {
        setLoading(true);
        setError(null); // Clear error before new request
        const wfService = new Organization();
        const response = await wfService.updateClubContactInfo(
          orgId,
          updateData
        );
        setData(response);
        // localizedNavigate(`/domui${clubDetailsPath}`);
        addSuccess(t('edit-club-contact.form.success', 'Update successful.'), {
          id: 'form.success',
        });
        getChannel('clubcontact').postMessage(
          t(
            'club-contact-tab-update.message',
            'Please refresh this browser tab to see updated information.'
          )
        );
        localizedNavigate(`${clubDetailsPath}`);
      }
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, updateClubContactInfo };
};
